import React from "react"
import { graphql } from 'gatsby'
import HtmlPage from "../../../../components/HtmlPage"

export default function Funeral({data}) {
  return <HtmlPage data={data}/>
}

export const query = graphql`
  query getFaith {
      markdownRemark(frontmatter: {url: {eq: "faith"}}) {
    id
    html
    frontmatter {
      title
      subtitle
      testimonial_card {
               image
          title
          subtitle
          position
          content
          org
      }
 
    }
  }
  }
`
